import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';

import { CloseIcon } from 'components/icons';
import { TabOutlineButton } from 'components/shared';
import { numberWithCommas } from 'lib/utils';

import ItemDrawerPalletContent from '../ItemDrawerPalletContent';
import ItemDrawerNeedTruck from '../ItemDrawerNeedTruck';

const NetsuiteOrderItemDrawer = ({
  open,
  closeDrawer,
  netsuiteOrderItem,
  classes,
}) => {
  const {
    name,
    netsuiteOrderNumber,
    quantity,
    truckRequired,
    trucks,
    vendorName,
  } = netsuiteOrderItem;

  const formattedQuantity = useMemo(() => numberWithCommas(quantity), [
    quantity,
  ]);

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={closeDrawer}
      classes={{ paper: classes.noScroll }}
      data-testid="netsuite-order-item-drawer"
    >
      <div
        data-testid="netsuite-order-item-drawer-root"
        className={classes.root}
      >
        <div className={classes.topSection}>
          <header>PO {netsuiteOrderNumber}</header>
          <TabOutlineButton
            className={classes.closeButton}
            type="button"
            data-testid="close-netsuite-order-item-drawer-button"
            onClick={closeDrawer}
          >
            <CloseIcon className={classes.icon} />
          </TabOutlineButton>
        </div>
        <div className={classes.bodySection}>
          <dl>
            <dt className={classes.infoLabel}>ITEM</dt>
            <dd className={classes.infoValue}>{name}</dd>
            <dt className={classes.infoLabel}>VENDOR</dt>
            <dd className={classes.infoValue}>{vendorName}</dd>
            <dt className={classes.infoLabel}>EXPECTED QUANTITY</dt>
            <dd className={classes.infoValue}>{formattedQuantity}</dd>
          </dl>
          {trucks.length > 0 || !truckRequired ? (
            <ItemDrawerPalletContent netsuiteOrderItem={netsuiteOrderItem} />
          ) : (
            <ItemDrawerNeedTruck
              closeDrawer={closeDrawer}
              netsuiteOrderItem={netsuiteOrderItem}
            />
          )}
        </div>
      </div>
    </Drawer>
  );
};

NetsuiteOrderItemDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  netsuiteOrderItem: PropTypes.shape({
    netsuiteOrderNumber: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vendorName: PropTypes.string,
    trucks: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

export default NetsuiteOrderItemDrawer;
