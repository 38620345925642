import { MEDIA_QUERY_PHONE_SCREEN, MEDIA_QUERY_TABLET } from 'lib/constants';

let x;
let allKeyFrames = {};
let otherStyles = {};

for (x = 0; x <= 70; x++) {
  const delay = Math.floor(Math.random() * 0.18);
  const duration = Math.floor(Math.random() * 85) + 5;
  const variable_distance = Math.floor(Math.random() * 100);
  const left = 100;
  allKeyFrames[`@keyframes petal-${x}`] = {
    '0%': {
      transform: 'rotate(0deg) scale(-0.25, 0.25)',
      right: `${variable_distance}%`,
      left: `-${left}%`,
      top: 90,
    },
    '25%': {
      transform: `rotate(${variable_distance}deg) scale(-0.25, 0.25)`,
      right: `${variable_distance + 2}%`,
    },
    '50%': {
      transform: `rotate(${variable_distance + 40}deg) scale(-0.25, 0.25)`,
      right: `${variable_distance}%`,
    },
    '75%': {
      transform: 'scale(-0.25, 0.25)',
      right: `${variable_distance - 2}%`,
      opacity: '0.9',
    },
    '100%': {
      transform: 'rotate(300deg) scale(-0.25, 0.25)',
      right: '98%',
      opacity: '0',
    },
  };

  otherStyles[`&:nth-of-type(${x})`] = {
    animationName: `$petal-${x}`,
    animationDelay: `${delay}s`,
    animationDuration: `${duration}s`,
    animationIterationCount: 'infinite',
    left: `${left}%`,
    top: `${variable_distance + 20}%`,
  };
}

allKeyFrames['@keyframes tree'] = {
  '0%': {
    transform: 'skewX(0deg) scale(0.72)',
  },
  '25%': {
    transform: 'skewX(1deg) scale(0.72) translateX(1px) scaleX(1.02)',
  },
  '50%': {
    transform: 'skewX(2deg) scale(0.72) translateX(2px) scaleX(1.04)',
  },
  '75%': {
    transform: 'skewX(1deg) scale(0.72) translateX(1px) scaleX(1.02)',
  },
  '100%': {
    transform: 'skewX(0deg) scale(0.72)',
  },
};

allKeyFrames['@keyframes clouds'] = {
  '0%': {
    transform: 'translateX(-20%)',
  },
  '100%': {
    transform: 'translateX(100%)',
  },
};

export default {
  willie: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/happy_spring_rabbit.png") no-repeat right bottom',
    backgroundSize: 'contain',
    position: 'absolute',
    bottom: '55px',
    right: '120px',
    zIndex: '-1',
    width: '100%',
    height: '200px',
    minHeight: '175px',
  },
  treeContainer: {
    position: 'absolute',
    bottom: '8%',
    right: '115%',
  },
  springTree: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/cherry_blossom_tree.png") no-repeat right bottom',
    backgroundSize: 'contain',
    position: 'absolute',
    zIndex: '-3',
    width: '100%',
    minWidth: '800px',
    bottom: '10px',
    height: '700px',
    transform: 'scale(1.1)',
  },
  springTreeBranches: {
    '&:after': {
      content:
        'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/cherry_blossom_tree_branches.png")',
    },
    position: 'absolute',
    bottom: '130px',
    left: '40px',
    zIndex: '-2',
    width: '100%',
    minHeight: '175px',
    height: '700px',
    animationName: '$tree',
    animationDelay: '1.5s',
    animationDuration: '6s',
    animationIterationCount: 'infinite',
    transform: 'scale(0.72)',
    animationTimingFunction: 'ease-in-out',
  },
  clouds: {
    position: 'absolute',
    '&:after': {
      content:
        'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/spring_clouds.png")',
    },
    zIndex: '-3',
    top: '5%',
    width: '100%',
    height: '200px',
    animationName: '$clouds',
    animationDuration: '30s',
    animationDelay: '0s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  // petals
  petal0: {
    position: 'absolute',
    '&:after': {
      content:
        'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/cherry_blossom_petal_1.png")',
    },
    ...otherStyles,
  },
  petal1: {
    position: 'absolute',
    '&:after': {
      content:
        'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/cherry_blossom_petal_2.png")',
    },
    ...otherStyles,
  },
  ...allKeyFrames,

  [MEDIA_QUERY_PHONE_SCREEN]: {
    treeContainer: {
      bottom: '6%',
      right: '170%',
      transform: 'scale(0.6)',
    },
    willie: {
      right: '-23%',
      bottom: '-22px',
      transform: 'scale(0.6)',
    },
  },
  [MEDIA_QUERY_TABLET]: {
    treeContainer: {
      bottom: '6%',
      right: '145%',
    },
    willie: {
      bottom: '35px',
      right: '35px',
    },
  },
};
