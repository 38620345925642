import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import unionWith from 'lodash/unionWith';
import sortBy from 'lodash/sortBy';

import PalletSubmissionForm from '../PalletSubmissionForm';
import PalletActivityLog from '../PalletActivityLog';

const ItemDrawerPalletContent = ({
  pallets,
  printPallet,
  deletePallet,
  palletsLoading,
  fetchPallets,
  measuringUnits = [],
  netsuiteOrderItem,
}) => {
  useEffect(() => {
    if (netsuiteOrderItem.id) {
      fetchPallets(netsuiteOrderItem.id);
    }
  }, [fetchPallets, netsuiteOrderItem.id]);

  const unitsOfMeasure = useMemo(() => {
    const combinedUnits = unionWith(
      measuringUnits,
      [
        {
          value: netsuiteOrderItem.measuringUnitId,
          label: netsuiteOrderItem.measuringUnitName,
        },
      ],
      (option, prevOption) => option.label === prevOption.label
    );
    return sortBy(combinedUnits, (unit) => unit.label.toLowerCase());
  }, [netsuiteOrderItem, measuringUnits]);

  const existingPalletQuantity = useMemo(() => {
    if (pallets.length > 0) {
      return pallets
        .map((pallet) => pallet.quantity)
        .reduce((accumulator, amount) => accumulator + amount);
    } else {
      return 0;
    }
  }, [pallets]);
  return (
    <div data-testid="item-drawer-pallet-content">
      <PalletSubmissionForm
        unitsOptions={unitsOfMeasure}
        existingPalletQuantity={existingPalletQuantity}
        netsuiteItem={netsuiteOrderItem}
      />
      <PalletActivityLog
        pallets={pallets}
        netsuiteOrderItemId={netsuiteOrderItem.id}
        printPallet={printPallet}
        deletePallet={deletePallet}
        loading={palletsLoading}
      />
    </div>
  );
};

const measuringUnitShape = PropTypes.shape({
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
});

ItemDrawerPalletContent.propTypes = {
  pallets: PropTypes.array.isRequired,
  measuringUnits: PropTypes.arrayOf(measuringUnitShape),
  printPallet: PropTypes.func.isRequired,
  deletePallet: PropTypes.func.isRequired,
  palletsLoading: PropTypes.bool.isRequired,
  fetchPallets: PropTypes.func.isRequired,
  netsuiteOrderItem: PropTypes.shape({
    netsuiteOrderNumber: PropTypes.string.isRequired,
    vendorName: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    measuringUnitName: PropTypes.string.isRequired,
    measuringUnitId: PropTypes.number.isRequired,
    defaultPrinterDescription: PropTypes.string.isRequired,
    overageLimit: PropTypes.number.isRequired,
    needOverageApproval: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ItemDrawerPalletContent;
