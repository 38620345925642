import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { TabOutlineButton } from 'components/shared/TabOutline';
import { printPallets } from 'redux/pallets/actions';

const ReprintPalletDialog = ({
  classes,
  showPrinterOptions,
  printerOptions,
  onReprintPallet = null,
  selectedPalletId = null,
  setShowPrinterOptions,
}) => {
  const dispatch = useDispatch();

  const reprintPallet = (printer = null) => {
    onReprintPallet
      ? onReprintPallet([selectedPalletId], printer)
      : dispatch(printPallets([selectedPalletId], printer));
  };

  const onPrinterSelect = (id) => {
    reprintPallet(id);
    setShowPrinterOptions(false);
  };

  return (
    <Dialog
      open={showPrinterOptions}
      onClose={() => setShowPrinterOptions(false)}
    >
      <DialogContent>
        <DialogContentText classes={{ root: classes.printerOptionsText }}>
          Which printer would you like to use?
        </DialogContentText>
      </DialogContent>
      <DialogContent classes={{ root: classes.printerButtonContainer }}>
        {printerOptions.map((printer) => (
          <TabOutlineButton
            type="button"
            className={classes.printerButton}
            key={printer.id}
            onClick={() => onPrinterSelect(printer.id)}
          >
            {printer.name}
          </TabOutlineButton>
        ))}
      </DialogContent>
    </Dialog>
  );
};

ReprintPalletDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  showPrinterOptions: PropTypes.bool.isRequired,
  printerOptions: PropTypes.array.isRequired,
  onReprintPallet: PropTypes.func,
  selectedPalletId: PropTypes.number,
  setShowPrinterOptions: PropTypes.func.isRequired,
};

export default ReprintPalletDialog;
