import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import useDebounce from 'lib/custom_hooks/useDebounce';

import styles from './styles';

const useStyles = makeStyles(styles);

const BinFilter = ({ setFilter }) => {
  const classes = useStyles();
  const [input, setInput] = useState('');

  const delayedFilter = useDebounce(async (inputValue) => {
    setFilter(inputValue);
  }, 500);

  useEffect(() => {
    delayedFilter(input);
  }, [input]);

  return (
    <Box className={classes.container}>
      <TextField
        fullWidth
        id="binventory-filter-input"
        InputProps={{
          className: classes.binput,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Bin ID or ingredient name"
        value={input}
      />
    </Box>
  );
};

BinFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
};

export default BinFilter;
