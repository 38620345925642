/* TimePickerWrapper
 * This component was created to help solve some major issues with TimePicker changes found in MUI 5
 * The interface to the wrapper is kept closely in line with the interface of the TimePicker itself
 * Note: This version currently only supports the 24hr clock

 * Problem #1: Default Times
   * The MUI TimePicker no longer sets the current time if no time is passed in
   * Solution: The wrapper keeps track of time for the picker, and sets it to current time if no time is passed in

 * Problem #2: Action buttons
   * The OK button doesn't regiester a click/time change if the user didn't make it
   * There is no API to know if the user clicked "Cancel" button
   * Solution: The wrapper hides MUI action buttons and creates its own ok/cancel buttons for better control

 * Problem #3: Cannot test Mobile Time Picker
   * To maintain previous styles we had to use the MobileTimePicker, which CANNOT be tested using RTL
   * Solution: use the StaticTimePicker to create our own component that behaves like the MobileTimePicker but can be tested
     * To do this we have to manually render the element that comes in as the `renderInput` prop
     * We also have to manually manage a modal that will show the StaticTimePicker
     * We only update what gets shown in the `renderInput` element when a time is accepted
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { StaticTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { formatTimeAsHHMM } from 'lib/utils';
import ContentModal from 'components/shared/ContentModal';

import styles from './styles';

const useStyles = makeStyles(styles);

const TimePickerWrapper = ({
  value = '',
  onChange = () => {},
  onAccept = () => {},
  renderInput = () => {},
  open = false,
  // the restOfProps will include any other props passed in, we don't really care about those so just pass them to the MUI component
  ...restOfProps
}) => {
  const classes = useStyles();
  // this is the time that is dispayed by the TimePicker
  const [time, setTime] = useState(value || new Date());
  // this is the time that shows up on the element outside of the TimePicker
  const [displayTime, setDisplayTime] = useState(
    value && formatTimeAsHHMM(value)
  );
  const [isOpen, setIsOpen] = useState(open);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const onTimeChange = (newTime) => {
    setTime(newTime);
    onChange(newTime);
  };

  const onClickOK = () => {
    onAccept(time);
    setDisplayTime(formatTimeAsHHMM(time));
    closeModal();
  };

  return (
    <div
      data-testid="time-picker-wrapper"
      className={classes.timePickerWrapper}
    >
      {renderInput({
        inputProps: { value: displayTime || '', onClick: openModal },
        ...restOfProps,
      })}
      <ContentModal
        open={isOpen}
        onClose={closeModal}
        data-testid="time-picker-modal"
        customFit
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticTimePicker
            ampm={false}
            value={time}
            className={classes.timePicker}
            onChange={onTimeChange}
            // the TimePicker still requires a render input if you want to type in the time
            renderInput={(params) => (
              <TextField data-testid="time-picker-input-field" {...params} />
            )}
            {...restOfProps}
          />
        </LocalizationProvider>
        <div className={classes.actionBar}>
          <Button
            data-testid="custom-cancel"
            variant="text"
            onClick={closeModal}
            sx={{ paddingRight: '20px', color: 'gray' }}
          >
            Cancel
          </Button>
          <Button
            data-testid="custom-ok"
            variant="contained"
            onClick={onClickOK}
          >
            OK
          </Button>
        </div>
      </ContentModal>
    </div>
  );
};

TimePickerWrapper.propTypes = {
  // value can be a date object, an ISO timestamp, or an empty string
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  onAccept: PropTypes.func,
  renderInput: PropTypes.func,
  open: PropTypes.bool,
};

export default TimePickerWrapper;
