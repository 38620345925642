import React from 'react';
import times from 'lodash/times';
import sample from 'lodash/sample';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);

const Spring = () => {
  const classes = useStyles();

  const petals = [classes.petal0, classes.petal1];

  return (
    <>
      <div>
        {times(30, (i) => {
          return <div key={i} className={sample(petals)} />;
        })}
      </div>
      <div className={classes.clouds} />
      <div className={classes.willie} />
      <div className={classes.treeContainer}>
        <div className={classes.springTree} />
        <div className={classes.springTreeBranches} />
      </div>
    </>
  );
};

export default Spring;
