import { BORDER_GREY, ROBOTO, GREY_600 } from 'lib/constants';

export const smallFontSize = '12px';
export const medFontSize = '14px';
export const largeFontSize = '20px';

export default {
  container: {
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: 8,
    boxShadow: 'none',
    fontFamily: ROBOTO,
  },
  controlsBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  selectPreText: {
    color: GREY_600,
    fontFamily: ROBOTO,
    fontSize: medFontSize,
    marginRight: '10px',
    display: 'inline',
  },
  selectText: {
    fontFamily: ROBOTO,
    fontSize: medFontSize,
  },
  switch: {
    color: GREY_600,
    display: 'inline',
    fontFamily: ROBOTO,
    fontSize: medFontSize,
    marginLeft: '25px',
  },
  wrapper: {
    marginTop: '20px',
    marginLeft: '16px',
    marginRight: '16px',
  },
};
