import { ROBOTO } from 'lib/constants';

export default {
  binput: {
    fontSize: '14px',
    fontFamily: ROBOTO,
    marginRight: 'auto',
    borderRadius: 8,
  },
  container: {
    width: '100%',
    marginBottom: '16px',
  },
};
