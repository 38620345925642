import React, {
  Fragment,
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MoreIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PrinterOptions = ({
  classes,
  printers,
  disabled,
  defaultPrinterDescription = '',
  setCurrentPrinterId = () => {},
  className = '',
}) => {
  const getInitialPrinter = useCallback(() => {
    let initialPrinterSelection;

    initialPrinterSelection = printers.find(
      (printer) => printer.description === defaultPrinterDescription
    );

    return initialPrinterSelection ? String(initialPrinterSelection.id) : '';
  }, [defaultPrinterDescription, printers]);
  const defaultPrinterId = useMemo(() => getInitialPrinter(), [
    getInitialPrinter,
  ]);
  const [selectedPrinterId, setSelectedPrinterId] = useState(getInitialPrinter);

  useEffect(() => {
    setCurrentPrinterId(selectedPrinterId);
  }, [selectedPrinterId]);

  const sortedPrinters = useMemo(
    () =>
      [...printers].sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (String(a.id) === defaultPrinterId) {
          return -1;
        } else if (String(b.id) === defaultPrinterId) {
          return 1;
        } else if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        }
        return 0;
      }),
    [printers, defaultPrinterId]
  );

  const handleChange = (e) => {
    setSelectedPrinterId(e.target.value);
  };

  const getDisplayName = (printer) => {
    if (String(printer.id) === defaultPrinterId) {
      return `${printer.name} (default)`;
    }
    return printer.name;
  };

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Fragment>
      <Button
        ref={anchorRef}
        disabled={disabled}
        className={classNames(classes.button, className)}
        variant="contained"
        color="primary"
        onClick={handleToggle}
        data-testid="printer-options-button"
        classes={{ disabled: classes.buttonDisabled }}
      >
        <MoreIcon />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleToggle}
      >
        <div className={classes.menu}>
          <FormControl variant="standard" component="fieldset">
            <div className={classes.title}>Change the printer</div>
            <RadioGroup
              aria-label="printer"
              value={selectedPrinterId}
              onChange={handleChange}
            >
              {sortedPrinters.map((printer) => (
                <FormControlLabel
                  value={String(printer.id)}
                  control={<Radio color="primary" />}
                  label={getDisplayName(printer)}
                  key={printer.id}
                  classes={{ label: classes.formControlLabel }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </Popover>
    </Fragment>
  );
};

PrinterOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  printers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  defaultPrinterDescription: PropTypes.string,
  setCurrentPrinterId: PropTypes.func,
  className: PropTypes.string,
};

export default PrinterOptions;
