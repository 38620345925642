import React from 'react';
import PropTypes from 'prop-types';

import { useTabOutline } from 'lib/custom_hooks';

const TabOutlineDiv = ({
  style = {},
  children = null,
  onMouseDown = () => {},
  onBlur = () => {},
  ...props
}) => {
  const [handleMouseDown, handleBlur, outlineStyle] = useTabOutline({
    onMouseDown,
    onBlur,
    style,
  });
  return (
    <div
      {...props}
      role="button"
      tabIndex={props.tabIndex}
      style={outlineStyle}
      onMouseDown={handleMouseDown}
      onBlur={handleBlur}
    >
      {children}
    </div>
  );
};

TabOutlineDiv.propTypes = {
  children: PropTypes.node,
  tabIndex: PropTypes.number,
  style: PropTypes.object,
  onBlur: PropTypes.func,
  onMouseDown: PropTypes.func,
};

export default TabOutlineDiv;
